<template>
  <h1>Perfil</h1>
  <profile></profile>
</template>

<script>
import { Profile } from '../components'
export default {
  name: 'ViewProfile',

  components: {
    Profile
  }
}
</script>
